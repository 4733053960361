.modal .auth-card .tab-content .button.is-fullwidth {
  padding: 0;
  max-width: 390px;
  margin: 20px auto;
}

.modal .auth-card .tab-content .field label {
  display: block;
    font-weight: 500;
    font-size: .9rem;
    margin-top: 20px;
}

.hidden-pages-field {
  margin-top: 10px;
}

.modal-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px; /* Adds padding inside the modal box */
  margin: 20px;  /* Adds margin around the modal box */
  max-width: 600px; /* Set a maximum width to control how wide the modal can get */
  background-color: white;
  border-radius: 8px; /* Add some rounding to the corners */
}

.qr-code-container {
  margin-top: 20px; /* Adds space between the text and the QR code */
}

.qr-code-image {
  max-width: 100%; /* Ensures the QR code image is responsive */
  height: auto;
  margin: 0 auto; /* Centers the QR code within its container */
}



.file {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  display: -webkit-box;
  display: -ms-flexbox;
  display: inline-block;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  position: relative;
}
.card-content {
  background-color: rgba(0, 0, 0, 0);
  text-overflow: ellipsis;
}

.file-name {
  border-color: #dbdbdb;
  border-style: none;
  border-width: 1px 1px 1px 0;
  display: block;
  max-width: 16em;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
}

.file-upload-warning {
  border-color: #dbdbdb;
  color: #c7c7c7;
  border-style: none;
  border-width: 1px 1px 1px 0;
  padding: 10px;
  display: block;
  max-width: 20em;
  overflow: visible;        /* Ensure no clipping */   
  text-overflow: ellipsis;
}

.login-not {
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 1.25rem 2.5rem 1.25rem 1.5rem;
  position: relative;
  margin-top: 20px;
}

@media screen and (max-width: 1024px) {
  .navbar-menu .navbar-item .signup-button {
    width: 100% !important;
  }
}

@media screen and (max-width: 1024px) {
  .navbar-menu .navbar-item {
    text-align: center !important;
  }
}

@media screen and (max-width: 1024px) {
  .navbar-start {
    text-align: center;
  }
}

.is-white {
  background-color: #fff !important;
}

.footer-grey {
  background-color: #ecf3fd;
}

@media screen and (max-width: 1024px) {
  .user-profile {
    width: 100% !important;
  }
}

@media screen and (max-width: 1024px) {
  .user-profile-dropdown {
    position: relative;
  }
}

.user-profile {
  width: auto !important;
}


.scrollTop {
  position: fixed; 
  width: 100%;
  bottom: 20px;
  align-items: center;
  height: 20px;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
}

.scrollTop:hover{
  opacity: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

.feature-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.icon-wrap {
  /* Icon styles here */
}

.filename {
  max-width: 150px; /* Set a maximum width for the filename */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
}

.filename-text {
  display: block;
}

.filename .filename-text::after {
  content: attr(title);
  position: absolute;
  top: -40px; /* Adjust the tooltip's position as needed */
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  background-color: #333; /* Dark background color */
  color: #fff; /* Text color */
  font-size: 16px; /* Increase the font size for the tooltip text */
  padding: 8px 12px; /* Add padding for better box styling */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
  z-index: 9999;
  pointer-events: none; /* Prevent tooltip from affecting mouse interactions */
  opacity: 0; /* Hide the tooltip by default */
  transition: opacity 0.3s; /* Add transition effect */
}

.filename:hover .filename-text::after {
  opacity: 1; /* Show tooltip on hover */
}
